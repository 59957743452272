<template>
    <b-navbar class="border-bottom pl-0">
        <b-navbar-toggle target="nav-text-collapse" />
        <b-navbar-brand class="border-right p-2" style="width: 66px;">
            <img src="/media/logos/icon150x150.png"
                 width="45"
                 height="45"
            >
        </b-navbar-brand>
        <b-collapse id="nav-text-collapse" is-nav>
            <b-navbar-nav v-if="customer" id="menu-information" class="min-w-250px">
                <b-nav-item-dropdown class="m-0">
                    <template slot="button-content">
                        <p>{{ customer && customer.company_name || "Firma" }}</p>
                        <b>{{ project && project.title || "Kein Projekt" }}</b>
                    </template>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="md-block mx-auto w-25">
                <search />
            </b-navbar-nav>
            <b-navbar-nav>
                <k-t-quick-user />
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import Search from '@/view/components/layout/Search'
import KTQuickUser from '@/view/layout/UserMenu'
import customerMixin from '@/core/mixins/customerMixin'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'Navbar',
    components: { KTQuickUser, Search },
    mixins: [customerMixin, projectMixin],
    computed: {
        user () {
            return this.$store.getters['auth/currentUser']
        },
        navbarProjectManagement () {
            return this.$route.meta.navbarProjectManagement || false
        }
    }
}
</script>

<style scoped>
p {
  margin: 0;
}

nav {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 13px;
}
</style>

<style>
#menu-information li a {
  padding: 0;
}
</style>
