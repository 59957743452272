<template>
    <div class="h-100">
        <Navbar />
        <router-view />
        <UserSidebar />
    </div>
</template>

<script>
import Navbar from '@/view/components/layout/Navbar'
import UserSidebar from '@/view/layout/UserSidebar'
export default {
    name: 'Layout',
    components: { UserSidebar, Navbar }
}
</script>

<style scoped>
</style>
