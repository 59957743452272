<template>
    <b-input size="sm"
             placeholder="Suchen"
             class="background h-30px"
    />
</template>

<script>
export default {
    name: 'Search'
}
</script>

<style scoped>
input {
  text-align: center;
}

.background {
  background: #F9F9F9;
}
</style>
