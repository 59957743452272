<template>
    <b-sidebar id="user-sidebar"
               right
               title="Profil"
               shadow
    >
        <template #footer>
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button size="sm"
                          block
                          style="color: #BC0003; background: rgba(188, 0, 3, 0.09);"
                          @click="onLogout"
                >
                    Abmelden
                </b-button>
            </div>
        </template>
        <div class="px-4 py-2">
            <div class="d-flex">
                <div class="rounded-circle d-flex align-items-center w-40px h-40px m-0 mt-1" style="background: rgba(188, 0, 3, 0.07);">
                    <svg width="19px"
                         height="19px"
                         viewBox="0 0 19 19"
                         class="bi bi-person d-block"
                         fill="#BC0003"
                         xmlns="http://www.w3.org/2000/svg"
                         style="margin: 0 auto;"
                    >
                        <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                    </svg>
                </div>
                <div class="ml-2">
                    <label class="m-0" style="color: #767676;">Nutzer</label>
                    <p>{{ user.first_name }} {{ user.last_name }}</p>
                </div>
            </div>
            <div class="d-flex">
                <div class="rounded-circle d-flex align-items-center w-40px h-40px m-0 mt-1" style="background: rgba(188, 0, 3, 0.07);">
                    <svg width="19px"
                         height="19px"
                         viewBox="0 0 19 19"
                         class="bi bi-building d-block"
                         fill="#BC0003"
                         xmlns="http://www.w3.org/2000/svg"
                         style="margin: 0 auto;"
                    >
                        <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                    </svg>
                </div>
                <div class="ml-2">
                    <label class="m-0" style="color: #767676;">Unternehmen</label>
                    <p>{{ user.company || '-' }}</p>
                </div>
            </div>
            <div class="d-flex">
                <div class="rounded-circle d-flex align-items-center w-40px h-40px m-0 mt-1" style="background: rgba(188, 0, 3, 0.07);">
                    <svg width="19px"
                         height="19px"
                         viewBox="0 0 19 19"
                         class="bi bi-envelope d-block"
                         fill="#BC0003"
                         xmlns="http://www.w3.org/2000/svg"
                         style="margin: 0 auto;"
                    >
                        <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                    </svg>
                </div>
                <div class="ml-2">
                    <label class="m-0" style="color: #767676;">Mail</label>
                    <p>{{ user.email }}</p>
                </div>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
export default {
    name: 'UserSidebar',
    computed: {
        user () {
            return this.$store.getters['auth/currentUser'] || { first_name: '', last_name: '', email: '' }
        }
    },
    methods: {
        onLogout () {
            this.$store
                .dispatch('auth/logout')
                .then(() => this.$router.push({ name: 'login' }))
        }
    }
}
</script>

<style scoped>

</style>
