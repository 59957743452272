<template>
    <div class="topbar-item">
        <a
            id="kt_quick_user_toggle"
            v-b-toggle.user-sidebar
            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        >
            <span
                class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
            >
                Hi,
            </span>
            <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
            >
                {{ user && user.first_name }}
            </span>
            <span class="symbol symbol-35 symbol-light-success">
                <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
                    {{ user && user.first_name && user.first_name[0] }}
                </span>
            </span>
        </a>
    </div>
</template>

<script>

export default {
    name: 'KTQuickUser',
    computed: {
        user () {
            return this.$store.getters['auth/currentUser']
        }
    },
    mounted () {
    }
}
</script>

<style scoped>

</style>
